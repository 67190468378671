import React, { Component } from 'react';
import './LiquidFillButton.scss';

class LiquidFillButton extends Component {
  render() {
    return (
      <div className="wrapper">
        <button>Liquid Fill</button>
        <button>Liquid Fill</button>
        <button>Liquid Fill</button>
        <button>Liquid Fill</button>
        <button>Liquid Fill</button>
        <button>Liquid Fill</button>
        <button>Liquid Fill</button>
        <button>Liquid Fill</button>
        <button>Liquid Fill</button>
        <button>Liquid Fill</button>
        <button>Liquid Fill</button>
        <button>Liquid Fill</button>
        <button>Liquid Fill</button>
        <button>Liquid Fill</button>
        <button>Liquid Fill</button>
        <button>Liquid Fill</button>
        <button>Liquid Fill</button>
        <button>Liquid Fill</button>
      </div>
    );
  }
}

export default LiquidFillButton;
